import { render, staticRenderFns } from "./_user_list.vue?vue&type=template&id=1db99de5&scoped=true&"
import script from "@/assets/js/components/admin/school_buying/common/_user_list.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/admin/school_buying/common/_user_list.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/admin/school_buying/common/_user_list.scss?vue&type=style&index=0&id=1db99de5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db99de5",
  null
  
)

export default component.exports