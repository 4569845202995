const embed = 'https://www.youtube.com/embed/';
const watch = 'https://www.youtube.com/watch?v=';
const regexp = /^.*(youtu.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*)(?:(\?t|&t|&start)=(\d+))?.*/;


const consts = {
    embed,
    watch,
    regexp
}

export default consts
