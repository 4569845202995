// common
import Dialogue from "@/components/common/dialogue.vue"
import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allBuyingBundlesIds', 'sidebarList'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            buyingBundlesList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            // 合購方案詳細資料，courses需要初始值，不然vue找不到參數會錯
            buyingBundlesDetail: {
                courses: [
                    {},
                    {}
                ]
            },
            buyingBundlesId: 0,
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "name",
                    itemText: "方案名稱",
                    ownClass: "name",
                    isShow: true
                },
                {
                    itemName: "course-A",
                    itemText: "課程A",
                    ownClass: "course-a",
                    isShow: true
                },
                {
                    itemName: "course-B",
                    itemText: "課程B",
                    ownClass: "course-b",
                    isShow: true
                },
                {
                    itemName: "discount",
                    itemText: "折扣金額",
                    ownClass: "discount",
                    isShow: true
                },
                {
                    itemName: "price",
                    itemText: "合購價格",
                    ownClass: "price",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allBuyingBundlesIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getBuyingBundlesList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getBuyingBundlesList() {
            return new Promise((resolve, reject) => {
                let buyingBundlesIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = this.allBuyingBundlesIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    buyingBundlesIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (buyingBundlesIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    courseBundleIds: buyingBundlesIds
                }

                this.$httpRequest.post('/admin_api/course_bundle/get_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    buyingBundlesIds.forEach(id => {
                                        let tempBuyingBundles = result[id];
                                        if (tempBuyingBundles) {
                                            this.buyingBundlesList.push({
                                                id: id,
                                                name: tempBuyingBundles.name,
                                                courses: tempBuyingBundles.courses,
                                                discount: tempBuyingBundles.discount,
                                                price: tempBuyingBundles.bundle_price,
                                                endDate: tempBuyingBundles.end_date
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/course/list/_table_list.js: 'getBuyingBundlesList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/list/_table_list.js: 'getBuyingBundlesList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/list/_table_list.js: 'getBuyingBundlesList' get error");
                    });
            });
        },
        showBuyingBundlesDetailDialogue(item) {
            this.buyingBundlesDetail = item;
            $('#buyingBundlesDetailDialogue').modal('show');
        },
        showDeleteBuyingBundlesDialogue(itemId) {
            this.buyingBundlesId = itemId;
            $('#deleteBuyingBundlesDialogue').modal('show');
        },
        deleteBuyingBundles() {
            let params = {
                bundleId: this.buyingBundlesId
            }

            this.$httpRequest.post('/admin_api/course_bundle/delete_bundle', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        $('#deleteBuyingBundlesDialogue').modal('hide');
                        this.buyingBundlesId = 0;
                        this.$parent.getBuyingBundlesIdsByState();
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
