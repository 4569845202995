// common
import Dialogue from "@/components/common/dialogue_client.vue"
import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['id', 'listItem'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue,
    },
    data: function() {
        return {
            allUserIds: [],
            userList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            isPostingApi: {
                adding: false,  // 新增學校訂單
                accountUploading: false, // 匯入帳號
                editing: false,          // 重新編輯資料
            }
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "id",
                    itemText: "ID",
                    ownClass: "id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "姓名",
                    ownClass: "name",
                    isShow: true
                },
                {
                    itemName: "account",
                    itemText: "帳號",
                    ownClass: "account",
                    isShow: true
                },
                {
                    itemName: "type",
                    itemText: "類別",
                    ownClass: "type",
                    isShow: true
                },
                {
                    itemName: "permission",
                    itemText: "權限",
                    ownClass: "permission",
                    isShow: true
                },
                {
                    itemName: "school-name",
                    itemText: "學校",
                    ownClass: "school-name",
                    isShow: true
                },
                {
                    itemName: "class",
                    itemText: "班級",
                    ownClass: "class",
                    isShow: true
                },
                {
                    itemName: "class-no",
                    itemText: "座號",
                    ownClass: "class-no",
                    isShow: true
                },
            ]
            list = list.filter(item => item.isShow);
            return list;
        }
    },
    methods: {
        dialougeLoadedHandler() {
            // 一開啟彈窗時
            $(`#${this.id}`).on('show.bs.modal', () => {
                this.initialize();
            });
        },
        initialize() {
            this.getUserIds()
                .then(() => {
                    if (this.allUserIds.length) {
                        // 一些參數初始化
                        this.busy = true;
                        this.isGetAllList = false;
                        this.userList = [];
                        this.loadingIndex = 0;
                        this.loadMore();
                    } else {
                        this.busy = false;
                        this.isGetAllList = true;
                    }
                })
            .catch(error => console.error('catched error: ' + error));

        },
        getUserIds() {
            return new Promise((resolve, reject) => {
            this.allUserIds = [];

            let params = {
                oslId: this.listItem.id
            }

            this.$httpRequest.get('/admin_api/user/get_import_user_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allUserIds = result;
                            if (result.length == 0 ) {
                                this.userList = [];
                            }
                        }
                        resolve();
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                    reject("admin/school_buying/common/_user_list.js: 'getUserIds' get error");
                });
            });
        },
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getUserList()
                    .then(() => this.busy = false)
                    .catch(error => console.error('catched error: ' + error));
            }
        },
        getUserList() {
            return new Promise((resolve, reject) => {
                let userIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempUserid = this.allUserIds[this.loadingIndex];
                    if (!tempUserid) {
                        this.isGetAllList = true;
                        break;
                    }
                    userIds.push(tempUserid);
                    this.loadingIndex++;
                }

                if (userIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    oslId: this.listItem.id,
                    userIds: userIds
                }

                this.$httpRequest.post('/admin_api/user/get_import_user_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    userIds.forEach(id => {
                                        let tempUserList = result[id];
                                        if (tempUserList) {

                                            this.userList.push({
                                                id: id,
                                                name: tempUserList.name,
                                                account: tempUserList.account,
                                                type: tempUserList.type,
                                                permission: tempUserList.permission,
                                                schoolName: tempUserList.school_name,
                                                class: tempUserList.class,
                                                classNo: tempUserList.class_no,
                                            });
                                        }
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => {
                        console.error('catched error: ' + error);
                        reject("admin/school_buying/common/_user_list.js: 'getUserList' get error");
                    });
            });
        },
        downloadUserlist() {
            let params = {
                id: this.listItem.id
            }
            this.$httpRequest.get('/admin_api/user/download_school_order_users', params)
                .then(async response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            try {
                                await this.$util.downloadFile(result.url, result.name);
                                this.isPostingApi.downloadExcel = false;
                            } catch (error) {
                                this.isPostingApi.downloadExcel = false;
                                console.error('Catched Error:', error);
                            }
                        }

                    }
                    else {
                        this.$store.dispatch('common/setAlert', { msg: '資料處理中，請稍後再試', status: 'danger' });
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                });
        }
    }
}