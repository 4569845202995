import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allArticleIds', 'sidebarList'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            articleList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "state",
                    itemText: "狀態",
                    ownClass: "article-state",
                    isShow: this.$route.params.subPage == 'all'
                },
                {
                    itemName: "admin",
                    itemText: "主編輯者",
                    ownClass: "article-admin",
                    isShow: this.$route.params.subPage != 'all'
                },
                {
                    itemName: "id",
                    itemText: "文章ID",
                    ownClass: "article-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "文章名稱",
                    ownClass: "article-name",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "article-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allArticleIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getArticleList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getArticleList() {
            return new Promise((resolve, reject) => {
                let articleIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempArticleId = this.allArticleIds[this.loadingIndex];
                    if (!tempArticleId) {
                        this.isGetAllList = true;
                        break;
                    }
                    articleIds.push(tempArticleId);
                    this.loadingIndex++;
                }

                if (articleIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    articleIds: articleIds
                }

                console.log('admin/article/list/_table_list.js - getArticleList', params);

                // fake data
                if (params.articleIds === undefined) {
                    reject();
                }

                let data = [
                    {
                        // id: '1',
                        name: '402期 柏林影展演員獎不再分男女',
                    },
                    {
                        // id: '2',
                        name: '404期 19歲蝙蝠俠助遊民 蜘蛛人受感召加入',
                    },
                    {
                        // id: '3',
                        name: '英文文法解析',
                    },
                    {
                        // id: '4',
                        name: '國小單字認識',
                    },
                    {
                        // id: '5',
                        name: '90天單字',
                    },
                    {
                        // id: '6',
                        name: '多益500單字',
                    }
                ]
                if (this.$route.params.subPage == 'all') {
                    for (let i in data) {
                        let tempState = `${parseInt(i)}`;
                        tempState = tempState == '5' ? '0' : tempState;
                        data[i].state = this.setStateText(tempState);
                    }
                } else {
                    for (let i in data) {
                        data[i].admin = `編輯者${parseInt(i)+1}號`;
                    }
                }
                while (this.articleList.length < this.loadingIndex) {
                    for (let i in data) {
                        if (this.articleList.length >= this.loadingIndex) {
                            break;
                        }
                        let copyData = Object.assign({ id: `${this.articleList.length+1}` }, data[i]);
                        this.articleList.push(copyData);
                    }
                }
                if (this.articleList.length == this.loadingIndex) {
                    resolve();
                }

                // !!! api
                // this.$httpRequest.post('')
                //     .then(response => {
                //         if (response.data.state == 'OK') {
                //             let result = response.data.result;

                //             if (result) {
                //                 result.forEach(el => {
                //                     this.articleList.push({
                //                         id: '1',
                //                         type: '訂閱課程',
                //                         name: '中學生報',
                //                         provider: '國語日報'
                //                     });
                //                 });

                //                 // 全部課程列表
                //                 if (this.$route.params.subPage == 'all') {
                //                     result.forEach(el => {
                //                         this.articleList.forEach(item => {
                //                             if (el.id == item.id) {
                //                                 item.state = this.setStateText('1');
                //                             }
                //                         });
                //                     });
                //                 }
                //                 // 各狀態課程列表
                //                 else {
                //                     result.forEach(el => {
                //                         this.articleList.forEach(item => {
                //                             if (el.id == item.id) {
                //                                 item.admin = '編輯者A';
                //                             }
                //                         });
                //                     });
                //                 }

                //                 resolve();
                //             } else {
                //                 reject("admin/article/list/_table_list.js: 'getArticleList' get error");
                //             }
                //         }
                //         if (response.data.state == 'ERROR') {
                //             reject("admin/article/list/_table_list.js: 'getArticleList' get error");
                //         }
                //     })
                //     .catch(function (error) {
                //         console.log('catched error: ' + error);
                //         reject("admin/article/list/_table_list.js: 'getArticleList' get error");
                //     });
            });
        },
        setStateText(state) {
            let filteredData = this.sidebarList.find(el => el.state == state);
            return filteredData.name;
        },
    }
}
