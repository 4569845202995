import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allArticleIds', 'isArticlePublicEditable'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            articleList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "state",
                    itemText: "狀態",
                    ownClass: "course-article-state",
                    isShow: true
                },
                {
                    itemName: "publish_time",
                    itemText: "排程上架",
                    ownClass: "course-article-publish-time",
                    isShow: true
                },
                {
                    itemName: "public",
                    itemText: "公開度",
                    ownClass: "course-article-public",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "文章ID",
                    ownClass: "course-article-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "文章名稱",
                    ownClass: "course-article-name",
                    isShow: true
                },
                {
                    itemName: "category",
                    itemText: "分類",
                    ownClass: "course-article-category",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "course-article-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        publicOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '付費'
                },
                {
                    value: '1',
                    text: '試閱'
                }
            ]
            return opts;
        },
    },
    created: function() {
        if (this.allArticleIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getArticleList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getArticleList() {
            return new Promise((resolve, reject) => {
                let articleIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempArticleId = this.allArticleIds[this.loadingIndex];
                    if (!tempArticleId) {
                        this.isGetAllList = true;
                        break;
                    }
                    articleIds.push(tempArticleId);
                    this.loadingIndex++;
                }

                if (articleIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    articleIds: articleIds
                }

                this.$httpRequest.post('/admin_api/article/get_article_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    articleIds.forEach(id => {
                                        let tempArticle = result[id];
                                        if (tempArticle) {
                                            this.articleList.push({
                                                id: tempArticle.id,
                                                state: tempArticle.state,
                                                publishTime: tempArticle.publish_date,
                                                isPublic: tempArticle.is_public,
                                                name: tempArticle.name,
                                                category: tempArticle.category_name
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/course/edit/_article_table_list.js: 'getArticleList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/edit/_article_table_list.js: 'getArticleList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/edit/_article_table_list.js: 'getArticleList' get error");
                    });
            });
        },
        updateArticlePublic(item) {
            if (!this.isArticlePublicEditable) {
                return;
            }

            let params = {
                courseId: this.$route.params.id,
                articleId: item.id,
                isPublic: item.isPublic
            }

            this.$httpRequest.post('/admin_api/article/update_article_public', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    // 如果更新失敗, 則回復原本公開度的設定
                    if (response.data.state == 'ERROR') {
                        item.isPublic = item.isPublic == '1' ? '0' : item.isPublic == '0' ? '1' : '';
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
