import { AndroidInterface } from '@/global/webview/AndroidInterface'
// components
const Success = resolve => require(["@/components/order/checkout/_success.vue"], resolve)
const Fail = resolve => require(["@/components/order/checkout/_fail.vue"], resolve)

export default {
    components: {
        Success,
        Fail
    },
    data: function() {
        return {
            interestedCourseList: [],  // 感興趣的課程列表
            exchangeCode: '',  // 贈送訂單的兌換課程代碼

            isSetDataReady: {
                getPurchaseResult: false,  // 取得購買結果
            }
        }
    },
    computed: {
        isAppWebview() {
            return this.$store.state.common.isAndroidWebview || this.$store.state.common.isiOSWebview;
        },
        isAndroidWebview() {
            return this.$store.state.common.isAndroidWebview;
        },
        isGiftOrder: {
            get() {
                return this.$store.state.order.isGiftOrder;
            },
            set(value) {
                this.$store.commit('order/setIsGiftOrder', value);
            }
        },
        finishedOrderItemList: {
            get() {
                return this.$store.state.order.finishedOrderItemList;
            },
            set(value) {
                this.$store.commit('order/setFinishedOrderItemList', value);
            }
        },
        componentType() {
            return this.$route.params.state;
        },
        ordersPageTab() {
            return this.finishedOrderItemList[0].purchaseType === '0' ? 'subscribe' : 'purchased';
        }
    },
    created: function() {
        // userAgent 非 App端的 Webview 時, 需取得購買結果資訊
        if (!this.isAppWebview) {
            this.getPurchaseResult();
            localStorage.removeItem('fromCourse');
        } else {
            this.isSetDataReady.getPurchaseResult = true;
        }
    },
    methods: {
        getPurchaseResult() {
            let params = {
                orderNo: this.$route.params.orderNo
            }

            this.$httpRequest.get('/api/order/get_purchase_result', params)
                .then(response => {
                    this.isGettingNewCoupon = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 若後端回應的訂單付款結果成功或失敗與網址不符, 則導回首頁
                            if ((this.$route.params.state === 'success' && !result.is_success) ||
                                (this.$route.params.state === 'fail' && result.is_success)) {
                                this.$router.push('/').catch(() => {});
                                return;
                            }

                            // 是否為贈送訂單
                            this.isGiftOrder = result.is_gift;
                            if (this.isGiftOrder && result.exchange_codes.length) {
                                this.exchangeCode = result.exchange_codes;  // 兌換代碼
                            }

                            // 訂單購買項目
                            for (let el of result.order_items) {
                                this.finishedOrderItemList.push({
                                    id: el.id,
                                    alias: el.alias,
                                    name: el.name,
                                    cover: el.cover_url,
                                    purchaseType: el.purchase_type
                                });
                            }

                            // 感興趣的課程列表
                            for (let el of result.interested_courses) {
                                this.interestedCourseList.push({
                                    id: el.id,
                                    alias: el.alias,
                                    name: el.name,
                                    subName: el.sub_name,
                                    cover: el.cover_url,
                                    purchaseType: el.purchase_type,
                                    price: el.price,
                                    suitable: el.suitable,
                                    difficultyLevel: el.difficulty_level
                                });
                            }

                            this.isSetDataReady.getPurchaseResult = true;
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.$router.push('/').catch(() => {});
                    }
                })
                .catch(error => {
                    this.isGettingNewCoupon = false;
                    console.error('Catched Error:', error);
                });
        },
        sendPurchasedStatusToWebview(status) {
            // Android
            if (this.isAndroidWebview) {
                AndroidInterface.sendPurchasedStatus(status);
            }
        }
    }
}
