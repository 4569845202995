import { marked } from 'marked'
import '@/global/markdown/md_preview.css'
// consts
import ytConst from '@/global/consts/youtube'

export default {
    props: {
        input: {
            required: true,
            type: String,
        },
    },
    computed: {
        compiledMarkdown() {
            return marked(this.removeHTMLTags(this.input));
        },
    },
    created: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            const renderer = new marked.Renderer();  // 建立渲染器

            // 自定義渲染語法規則
            // 連結、音檔、影片
            renderer.link = function (href, title, text) {
                // youtube影片
                if (text === '_yt') {
                    const match = href.match(ytConst.regexp);

                    if (!(match && match[2].length === 11)) {
                        return '';
                    }

                    const start = match[3] && match[4] ? `start=${match[4]}&` : '';

                    return `
                        <div class="md-video">
                            <iframe src="${ytConst.embed}${match[2]}?${start}playsinline=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    `;
                }
                // 上傳音檔或影片
                if (text === '_audio' || text === '_video') {
                    const regexp = /^.*\?id=([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/g;
                    const match = href.match(regexp);

                    if (!match) {
                        return '';
                    }

                    switch (text) {
                        case '_audio': {
                            return `
                                <audio autobuffer controls controlsList="nodownload" preload="auto">
                                    <source src="${href}">
                                </audio>
                            `;
                        }
                        case '_video': {
                            return `
                                <div class="md-video">
                                    <video autobuffer controls controlsList="nodownload" disablePictureInPicture preload="auto">
                                        <source src="${href}">
                                    </video>
                                </div>
                            `;
                        }
                    }
                }
                // 其他連結則修改為開分頁方式打開連結
                return `<a href="${href}" target="_blank">${text}</a>`;
            }

            // 初始化設定
            marked.setOptions({
                renderer: renderer,
                breaks: true
            });
        },
        removeHTMLTags(string) {
            let newStr = string.replace(/\r\n|\r|\n/g, '\n');  // 將所有換行符號一致取代為 \n
            let arys = newStr.split('\n');  // 依據 \n 切開字串為陣列

            let newArys = [];
            let isCodeBlock = false;
            const underlineRegex = /<u\s*.*?>\s*.*?<\/u>/g;
            const fontColors = ['#3E3A38', '#E05252', '#F07525', '#23A4EC', '#43C65E'];
            const fontColorRegex = new RegExp(`<font color="(${fontColors.join('|')})">\\s*.*?<\\/font>`, 'g');
            for (let el of arys) {
                // 進入或結束程式碼區塊
                if (el.includes('```')) {
                    isCodeBlock = !isCodeBlock;
                }

                // 例外狀況則直接將元素內容放入新陣列
                if (isCodeBlock || el.match(underlineRegex) || el.match(fontColorRegex)) {
                    newArys.push(el);
                }
                // 非例外則將內容含有 html tag 的部分取代為空
                else {
                    newArys.push(el.replace(/(<([^>]+)>)/gi, ''));
                }
            }

            let result = newArys.join('\n');  // 最後再用 \n 將陣列接回成字串
            return result;
        },
    },
}
