export default {
    props: {
        sidebarList: {
            required: true,
            type: Array
        },
        additionalSidebarList: {
            required: false,
            type: Array,
            default: function() {
                return []
            }
        },
        backUrl: {
            required: false,
            type: String,
            default: ''
        },
    },
    methods: {
        clickLink() {
        }
    }
}
