// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    props: ['courseBasicInfo'],
    components: {
        Dialogue
    },
    data: function() {
        return {
            activeTab: this.getActiveTab(),

            activeRemoveDialogue: '',
            targetAction: '',
            noticeMsg: ''
        }
    },
    computed: {
        editRemoveSettingsTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'delete',
                    text: '移除通知',
                    isShow: ['0', '1', '2', '5'].includes(this.courseBasicInfo.state)
                },
                {
                    value: 'stopSelling',
                    text: '停售課程',
                    isShow: ['3'].includes(this.courseBasicInfo.state)
                },
                {
                    value: 'archive',
                    text: '下架封存',
                    isShow: ['4'].includes(this.courseBasicInfo.state)
                }
            ]
            tabs = tabs.filter(item => item.isShow);
            return tabs;
        },
    },
    methods: {
        getActiveTab() {
            switch (this.courseBasicInfo.state) {
                case '0': case '1': case '2': case '5':
                    return 'delete';
                case '3':
                    return 'stopSelling';
                case '4':
                    return 'archive';
            }
        },
        showRemoveCoursePromptDialogue(newAction) {
            this.activeRemoveDialogue = '';
            this.targetAction = newAction;

            switch (this.targetAction) {
                // 上架中 -> 停售中
                case 'stopSelling':
                    this.activeRemoveDialogue = 'stopSellingCoursePromptDialogue';
                    break;
                // 停售中 -> 已封存
                case 'archive':
                    this.activeRemoveDialogue = 'archiveCoursePromptDialogue';
                    break;
                // 移除課程
                case 'delete':
                    this.activeRemoveDialogue = 'deleteCoursePromptDialogue';
                    break;
            }

            if (!this.activeRemoveDialogue) {
                return;
            }

            $(`#${this.activeRemoveDialogue}`).modal('show');
        },
        handleChangingCourseState() {
            if (this.$parent.isPostingApi.changeState) {
                return;
            }

            let options = {
                activeDialogue: this.activeRemoveDialogue,
                targetAction: this.targetAction,
                noticeMsg: this.noticeMsg
            }

            this.$parent.changeCourseState(options);
        }
    }
}
