import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"
import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allCouponIds', 'type', 'sidebarList'],
    directives: {
        InfiniteScroll
    },
    components: {
        DatePicker,
        Dialogue
    },
    data: function() {
        return {
            typeName: '',
            couponList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            // 優惠券詳細資料，courses需要初始值，不然vue找不到參數會錯
            couponData: {
                courses: [
                    {},
                    {}
                ]
            },
            couponId: 0,
            endDate: '',
            isPostingApi: {
                editCoupon: false  // 編輯優惠券
            }
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "name",
                    itemText: this.typeName + "碼 | 名稱",
                    ownClass: "coupon-name",
                    isShow: true
                },
                {
                    itemName: "organization",
                    itemText: "通路",
                    ownClass: "coupon-organization",
                    isShow: true
                },
                {
                    itemName: "discount",
                    itemText: this.type == 0 ? "體驗期" : "折扣金額",
                    ownClass: "coupon-discount",
                    isShow: true
                },
                {
                    itemName: "count",
                    itemText: "數量限制",
                    ownClass: "coupon-count",
                    isShow: true
                },
                {
                    itemName: "use_count",
                    itemText: "實際領用",
                    ownClass: "coupon-use-count",
                    isShow: true
                },
                {
                    itemName: "status",
                    itemText: "狀態 | 效期",
                    ownClass: "coupon-status",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "coupon-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        this.setCouponPage();
        if (this.allCouponIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        setCouponPage() { // 因為coupon共用一個js，需檢查目前在哪一個優惠券頁面，主要為重新設定參數typeName(0:體驗、1:優訂、2:折扣、3:全站)
            switch(this.type) {
                case 0:
                    this.typeName = '體驗';
                    break;
                case 1:
                    this.typeName = '優訂';
                    break;
                case 2:
                    this.typeName = '折扣';
                    break;
                case 3:
                    this.typeName = '全站';
                    break;
            }
        },
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getCouponList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getCouponList() {
            return new Promise((resolve, reject) => {
                let couponIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = this.allCouponIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    couponIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (couponIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    couponIds: couponIds
                }

                this.$httpRequest.post('/admin_api/coupon/get_coupon_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    couponIds.forEach(id => {
                                        let tempCoupon = result[id];
                                        if (tempCoupon) {
                                            let startDate = this.$util.unixTimestampToDatetime(tempCoupon.start_date, this.$util.getBrowserCurrentTimeZone(), 'datetime', true),
                                                endDate = this.$util.unixTimestampToDatetime(tempCoupon.end_date, this.$util.getBrowserCurrentTimeZone(), 'datetime', true);
                                            this.couponList.push({
                                                id: id,
                                                code: tempCoupon.code,
                                                codeType: tempCoupon.code_type,
                                                name: tempCoupon.name,
                                                organization: tempCoupon.organization,
                                                courseId: tempCoupon.applicable_course ? tempCoupon.applicable_course : '',
                                                courseName: tempCoupon.course_name ? tempCoupon.course_name : '',
                                                discountType: tempCoupon.discount_type,
                                                discount: tempCoupon.discount_type < 2 ? tempCoupon.discount : tempCoupon.discount % 10 == 0 ? tempCoupon.discount / 10 : tempCoupon.discount,
                                                count: tempCoupon.count,
                                                useCount: tempCoupon.use_count,
                                                receiveCount: tempCoupon.receive_count,
                                                startDate: startDate,
                                                endDate: endDate,
                                                isDeleted: tempCoupon.is_deleted,
                                                status: new Date() < new Date(startDate) ? '未開始' : new Date() > new Date(startDate) && new Date() < new Date(endDate) ? '進行中' : '已結束'
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/course/list/_table_list.js: 'getCouponList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/list/_table_list.js: 'getCouponList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/list/_table_list.js: 'getCouponList' get error");
                    });
            });
        },
        copyCode(index) {
            let textCopy = document.querySelector('#code' + index);
            textCopy.setAttribute('type', 'text'); // 不是隱藏(hidden)才能複製
            textCopy.select();
            let successful = document.execCommand('copy');
            if (successful) {
                this.$store.dispatch('common/setAlert', { msg: '複製成功', status: 'success' });
            }
            /* unselect the range */
            textCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        showCouponDetailDialogue(item) {
            this.couponData = this.$_.cloneDeep(item);
            $('#couponDetailDialogue').modal('show');
        },
        disableEndDate(date) {
            // disable小於有設定的開始時間
            return this.couponData.startDate && date.setHours(0, 0, 0, 0) < new Date(this.couponData.startDate).setHours(0, 0, 0, 0);
        },
        disableEndTime(date) {
            // disable小於有設定的開始時間(時)
            return this.couponData.startDate && date <= new Date(this.couponData.startDate);
        },
        showEditCouponDialogue(item) {
            this.couponData = this.$_.cloneDeep(item);
            this.couponId = item.id;
            this.endDate = item.endDate;
            $('#editCouponDialogue').modal('show');
        },
        editCoupon() {
            let params = {
                    couponInfo:{
                        courseCouponInfoId: this.couponId,
                        startDate: this.$util.datetimeToUnixTimestamp(this.couponData.startDate),
                        endDate: this.$util.datetimeToUnixTimestamp(this.couponData.endDate)
                    }
                }

            this.$httpRequest.post('/admin_api/coupon/update_coupon', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.couponList.forEach((item) =>{
                            if (item.id == this.couponId) {
                                item.endDate = this.couponData.endDate;
                                item.status = new Date() < new Date(item.startDate) ? '未開始' : new Date() > new Date(item.startDate) && new Date() < new Date(item.endDate) ? '進行中' : '已結束'
                            }
                        });
                        $('#editCouponDialogue').modal('hide');
                        this.couponId = 0;
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showDeleteCouponDialogue(itemId) {
            this.couponId = itemId;
            $('#deleteCouponDialogue').modal('show');
        },
        deleteCoupon() {
            let params = {
                couponInfoId: this.couponId
            }

            this.$httpRequest.post('/admin_api/coupon/delete_coupon', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        $('#deleteCouponDialogue').modal('hide');
                        this.couponId = 0;
                        this.$parent.getCouponIdsByState();
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
